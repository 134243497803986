import './App.css';
import MainNav from './components/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home';
import Footer from './components/footer';
import React from "react";
import MyAccount from './pages/myaccount';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from './pages/login';
import Signup from './pages/cont-nou';
import ForgotPassword from './pages/forgot-password.jsx';
import Contact from './pages/contact';
import RCAForm from './pages/rca';
import UserPage from './pages/userpanel';
import { Provider } from 'react-redux';
import store from './backend/store';
import ProtectedRoute from './backend/protectedRoute';
import PublicRoute from './backend/publicRoute.js';
import TermsAndConditions from './pages/termeni-si-conditii';
import PrivacyPolicy from './pages/politica-gdpr';
import CookiePolicy from './pages/politica-cookies';

function App() {
  return (
    <Provider store={store}> {/* Wrap the entire app in the Provider */}
      <div>
        <MainNav />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/login" element={<PublicRoute component={<Login />} />} />
            <Route path="/cont-nou" element={<PublicRoute component={<Signup />} />} />
            <Route path="/forgot-password" element={<PublicRoute component={<ForgotPassword />} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/rca" element={<RCAForm />} />
            <Route path="/userpanel" element={<ProtectedRoute component={<UserPage />} />} />
            <Route path="/contul-meu" element={<ProtectedRoute component={<MyAccount />} />} />
            <Route path="/termeni-si-conditii" element={<TermsAndConditions />} />
            <Route path="/politica-de-confidentialitate" element={<PrivacyPolicy />} />
            <Route path="/politica-cookies" element={<CookiePolicy />} />
          </Routes>
        </Router>
        <Footer />
      </div>
    </Provider>
  );
}

export default App;
