import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container py-5">
      <header>
        <h1>Politica de Confidențialitate</h1>
      </header>
      <main>
        <section>
          <h2>1. Introducere</h2>
          <p>
            La <strong>Blu Insurance Company SRL</strong>, respectăm confidențialitatea și ne angajăm să protejăm
            datele personale ale clienților noștri. Această politică explică cum colectăm, utilizăm, stocăm și
            protejăm informațiile dumneavoastră personale.
          </p>
        </section>
        <section>
          <h2>2. Operatorul de date</h2>
          <p>
            Operatorul de date este <strong>Blu Insurance Company SRL</strong>, cu următoarele detalii de contact:
          </p>
          <ul>
            <li><strong>Denumire:</strong> Blu Insurance Company SRL</li>
            <li><strong>CUI:</strong> 47596717</li>
            <li><strong>Registru Comerț:</strong> J40/14099/2024</li>
            <li><strong>Adresă:</strong> București, Bd. Lacul Tei 79</li>
            <li><strong>Email:</strong> contact@erca.ro</li>
            <li><strong>Telefon:</strong> +40 722 985 433</li>
          </ul>
        </section>
        <section>
          <h2>3. Datele personale colectate</h2>
          <p>Colectăm următoarele categorii de date personale:</p>
          <ul>
            <li>Nume și prenume</li>
            <li>Detalii de pe buletin (poză sau PDF)</li>
            <li>Permis auto (poză sau PDF)</li>
            <li>Certificat de înmatriculare auto (poză sau PDF)</li>
            <li>Kilometrii auto</li>
            <li>Număr de telefon</li>
            <li>Seria de șasiu</li>
          </ul>
          <p>
            Aceste date sunt necesare pentru furnizarea serviciilor noastre, precum notificarea expirării documentelor
            auto prin e-mail sau SMS.
          </p>
        </section>
        <section>
          <h2>4. Scopurile prelucrării datelor</h2>
          <p>Datele personale sunt prelucrate în următoarele scopuri:</p>
          <ul>
            <li>Crearea și administrarea contului de utilizator</li>
            <li>Furnizarea notificărilor privind expirarea documentelor auto</li>
            <li>Contactarea utilizatorilor pentru clarificări sau asistență</li>
            <li>Îndeplinirea obligațiilor legale</li>
          </ul>
        </section>
        <section>
          <h2>5. Temeiul legal pentru prelucrare</h2>
          <p>Prelucrarea datelor personale se face în baza următoarelor temeiuri legale:</p>
          <ul>
            <li>Executarea unui contract (serviciile furnizate de noi)</li>
            <li>Consimțământul explicit al utilizatorului</li>
            <li>Respectarea obligațiilor legale</li>
          </ul>
        </section>
        <section>
          <h2>6. Drepturile utilizatorilor</h2>
          <p>Conform legislației GDPR, aveți următoarele drepturi:</p>
          <ul>
            <li>Dreptul de acces la datele personale</li>
            <li>Dreptul de rectificare a datelor personale</li>
            <li>Dreptul de ștergere ("dreptul de a fi uitat")</li>
            <li>Dreptul de restricționare a prelucrării</li>
            <li>Dreptul de portabilitate a datelor</li>
            <li>Dreptul de a vă opune prelucrării</li>
            <li>Dreptul de a depune o plângere la autoritatea de supraveghere</li>
          </ul>
        </section>
        <section>
          <h2>7. Stocarea și securitatea datelor</h2>
          <p>
            Datele personale sunt stocate în condiții de securitate, utilizând măsuri tehnice și organizatorice adecvate
            pentru a preveni accesul neautorizat, pierderea sau distrugerea acestora.
          </p>
          <p>
            Datele sunt păstrate doar atât timp cât este necesar pentru scopurile menționate sau conform cerințelor legale.
          </p>
        </section>
        <section>
          <h2>8. Partajarea datelor cu terțe părți</h2>
          <p>
            Nu partajăm datele dumneavoastră personale cu terțe părți, cu excepția cazurilor prevăzute de lege sau când
            este necesar pentru furnizarea serviciilor (de exemplu, partenerii de notificare SMS).
          </p>
        </section>
        <section>
          <h2>9. Modificări ale politicii de confidențialitate</h2>
          <p>
            Ne rezervăm dreptul de a actualiza această politică. Modificările vor fi comunicate utilizatorilor prin e-mail
            sau notificări în platformă.
          </p>
        </section>
        <section>
          <h2>10. Contact</h2>
          <p>
            Pentru orice întrebări sau solicitări legate de această politică de confidențialitate, vă rugăm să ne contactați:
          </p>
          <p>
            <strong>Email:</strong> contact@erca.ro<br />
            <strong>Telefon:</strong> +40 722 985 433<br />
            <strong>Adresă:</strong> București, Bd. Lacul Tei 79
          </p>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 Blu Insurance Company SRL - Toate drepturile rezervate.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
