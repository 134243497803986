import React, { useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import './rca.css';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../backend/firebaseConfig';
import { v4 as uuidv4 } from 'uuid';

function RCAForm() {
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [fileNames, setFileNames] = useState({
    certificat: "Niciun fișier încărcat",
    buletinProprietar: "Niciun fișier încărcat",
    permisAuto: "Niciun fișier încărcat",
    buletinSofe: "Niciun fișier încărcat",
  });

  const handleFileChange = (event, field) => {
    const file = event.target.files[0];
    setFileNames((prev) => ({
      ...prev,
      [field]: file ? file.name : "Niciun fișier încărcat",
    }));
  };

  
  const uploadFilesToStorage = async (files, randomFolderName) => {
    try {
      // Convert the files object to an array of [key, file]
      const uploadPromises = Object.entries(files).map(async ([key, file]) => {
        if (!file) {
          // Skip null/undefined entries
          return { [key]: null };
        }
  
        // Define the path in Storage (folder + file name)
        const filePath = `/${randomFolderName}/${file.name}`;
        const fileRef = ref(storage, filePath);
  
        // Upload the file
        await uploadBytes(fileRef, file);
  
        // Get the download URL
        const downloadURL = await getDownloadURL(fileRef);
  
        // Return { keyName: "downloadURL" }
        return { [key]: downloadURL };
      });
  
      // Wait for all uploads to complete
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Error uploading files:', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setError(""); // Clear previous error messages
    setSuccess(""); // Clear previous success messages
    if (form.checkValidity() === false) {
      setError("Formularul nu a fost completat corespunzător");
      event.stopPropagation();
      return;
    }
  
    setValidated(true);
  
    try {
      // Prepare form data
      const kmVehicul = form["formKMVehicul"].value;
      const itpValabil = form["formITPValabil"].value;
      const phone = form["formPhone"].value;
  
      // Files to upload
      const files = {
        certificat: form["certificat"].files[0],
        buletinProprietar: form["buletinProprietar"].files[0],
        permisAuto: form["permisAuto"].files[0],
        buletinSofe: form["buletinSofe"].files[0],
      };

      const randomFolderName = uuidv4();
      await uploadFilesToStorage(files, randomFolderName);

      try {
        await fetch(`https://sendrcaformemail-cjq2kkjshq-uc.a.run.app?kmVehicul=${kmVehicul}&itpValabil=${itpValabil}&phone=${phone}&name=${randomFolderName}`);
      } catch (error) {
        console.error('Error calling function:', error);
      }

      setSuccess("Formularul a fost trimis cu succes!");
    } catch (error) {
      setError("Ne cerem scuze, a apărut o eroare internă");
      console.error("Error during form submission:", error);
    }
  };

  return (
    <Container className="rca-form-container py-5">
      <h2 className="text-center mb-1">Achiziționare RCA Online</h2>
      <p className="text-body-emphasize lead text-center">
        Nu trebuie să plătești nimic momentan. Completează datele, iar noi te vom contacta curând pentru a-ți comunica ofertele pentru polița RCA.
      </p>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">{success}</div>}
      <Form noValidate validated={validated} onSubmit={handleSubmit} className="mt-4">

        {/* Certificat Înmatriculare */}
        <Form.Group controlId="formCertificatInmatriculare" className="mb-3">
          <Form.Label>Certificat Înmatriculare (Talon)</Form.Label>
          <div className="custom-file-input">
            <input
              type="file"
              id="certificat"
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={(e) => handleFileChange(e, "certificat")}
              required
              style={{ display: "none" }}
            />
            <Button
              variant="secondary"
              className="custom-file-button"
              onClick={() => document.getElementById("certificat") .click()}
            >
              Încarcă fișier
            </Button>
            <span className="file-name ms-3">{fileNames.certificat}</span>
          </div>
          <Form.Control.Feedback type="invalid">Fișierul este necesar!</Form.Control.Feedback>
        </Form.Group>

        {/* Buletin Proprietar / CUI */}
        <Form.Group controlId="formBuletinProprietar" className="mb-3">
          <Form.Label>Buletin Proprietar / CUI</Form.Label>
          <div className="custom-file-input">
            <input
              type="file"
              id="buletinProprietar"
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={(e) => handleFileChange(e, "buletinProprietar")}
              required
              style={{ display: "none" }}
            />
            <Button
              variant="secondary"
              className="custom-file-button"
              onClick={() => document.getElementById("buletinProprietar").click()}
            >
              Încarcă fișier
            </Button>
            <span className="file-name ms-3">{fileNames.buletinProprietar}</span>
          </div>
          <Form.Control.Feedback type="invalid">Fișierul este necesar!</Form.Control.Feedback>
        </Form.Group>

        {/* Permis Auto Șofer */}
        <Form.Group controlId="formPermisAuto" className="mb-3">
          <Form.Label>Permis Auto Șofer</Form.Label>
          <div className="custom-file-input">
            <input
              type="file"
              id="permisAuto"
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={(e) => handleFileChange(e, "permisAuto")}
              required
              style={{ display: "none" }}
            />
            <Button
              variant="secondary"
              className="custom-file-button"
              onClick={() => document.getElementById("permisAuto").click()}
            >
              Încarcă fișier
            </Button>
            <span className="file-name ms-3">{fileNames.permisAuto}</span>
          </div>
          <Form.Control.Feedback type="invalid">Fișierul este necesar!</Form.Control.Feedback>
        </Form.Group>

        {/* Buletin Șofer Auto */}
        <Form.Group controlId="formBuletinSofe" className="mb-3">
          <Form.Label>Buletin Șofer Auto (Dacă este diferit de proprietarul mașinii)</Form.Label>
          <div className="custom-file-input">
            <input
              type="file"
              id="buletinSofe"
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={(e) => handleFileChange(e, "buletinSofe")}
              style={{ display: "none" }}
            />
            <Button
              variant="secondary"
              className="custom-file-button"
              onClick={() => document.getElementById("buletinSofe").click()}
            >
              Încarcă fișier
            </Button>
            <span className="file-name ms-3">{fileNames.buletinSofe}</span>
          </div>
        </Form.Group>

        {/* KM Vehicul */}
        <Form.Group controlId="formKMVehicul" className="mb-3">
          <Form.Label>Kilometri Vehicul (Asiguratorul GRAWE te roagă să introduci aceste date)</Form.Label>
          <Form.Control type="number" placeholder="Introduceți numărul de kilometri" required />
          <Form.Control.Feedback type="invalid">Introduceți numărul de kilometri!</Form.Control.Feedback>
        </Form.Group>

        {/* ITP Valabil până la */}
        <Form.Group controlId="formITPValabil" className="mb-3">
          <Form.Label>ITP Valabil până la: (Asiguratorul GENERALI te roagă să introduci această informație)</Form.Label>
          <Form.Control type="date" required />
          <Form.Control.Feedback type="invalid">Alegeți o dată!</Form.Control.Feedback>
        </Form.Group>

        {/* Phone number */}
        <Form.Group controlId="formPhone" className="mb-3">
          <Form.Label>Număr de telefon</Form.Label>
          <Form.Control type="phone" placeholder="Introduceți numărul de telefon" required />
          <Form.Control.Feedback type="invalid">Introduceți numărul de telefon!</Form.Control.Feedback>
        </Form.Group>

        
        {/* Checkbox Politica de Confidențialitate */}
        <Form.Group controlId="formPrivacyPolicy" className="mb-3">
          <Form.Check
            type="checkbox"
            label="Sunt de acord cu prelucrarea datelor, conform politicii de confidențialitate."
            required
          />
          <Form.Control.Feedback type="invalid">Trebuie să fiți de acord cu prelucrarea datelor!</Form.Control.Feedback>
        </Form.Group>

        {/* Submit Button */}
        <Button variant="primary" type="submit" className="w-100 py-2 buton-rca">
          Trimite
        </Button>
      </Form>
    </Container>
  );
}

export default RCAForm;
