import React, { useState, useEffect } from "react";
import { ref, onValue, update } from "firebase/database"; // <-- Note: use update
import { database } from "../backend/firebaseConfig";
import { useSelector } from "react-redux";
import "./userpanel.css";

const UserPage = () => {
  const initialFields = [
    { tag: "politaRCA", label: "Asigurare RCA", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "itp", label: "ITP", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "rovinieta", label: "Rovinieta", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "asigurareCASCO", label: "Asigurare CASCO", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "impozitAuto", label: "Impozit Auto", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "permisAuto", label: "Permis Auto", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "actIdentitate", label: "Act de Identitate", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "trusaPrimAjutor", label: "Trusă Prim-Ajutor", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "stingator", label: "Expirare Stingător", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "atestat", label: "Expirare Atestat", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "abonamentParcare", label: "Abonament Parcare", value: "", mailEnabled: false, smsEnabled: false },
    { tag: "revizieGPL", label: "Revizie GPL", value: "", mailEnabled: false, smsEnabled: false },
  ];

  const [activeSection, setActiveSection] = useState("section1");
  const [data, setData] = useState({
    section1: { carName: "Masina 1", notepad: "", fields: initialFields },
    section2: { carName: "Masina 2", notepad: "", fields: initialFields },
    section3: { carName: "Masina 3", notepad: "", fields: initialFields },
  });

  // This controls "edit mode" for both fields and notepad
  const [isEditing, setIsEditing] = useState(false);

  // For loading indicator
  const [loading, setLoading] = useState(true);

  const authUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (authUser) {
      const userRef = ref(database, `users/${authUser.uid}`);
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData && userData.sections) {
          const mergedData = {};
          const sections = userData.sections;

          // Merge retrieved data with initial fields structure
          Object.keys(sections).forEach((section) => {
            const sectionData = sections[section] || {};
            const sectionFields = sectionData.fields || [];

            mergedData[section] = {
              carName: sectionData.carName || "Numar Inmatriculare",
              notepad: sectionData.notepad || "",
              fields: initialFields.map((field) => {
                const savedField = sectionFields.find((item) => item.tag === field.tag);
                return savedField
                  ? {
                      ...field,
                      value: savedField.value || "",
                      mailEnabled: savedField.mailEnabled ?? false,
                      smsEnabled: savedField.smsEnabled ?? false,
                    }
                  : field;
              }),
            };
          });

          setData(mergedData);
        } else {
          // No user data, initialize with defaults
          setData({
            section1: { carName: "Masina 1", notepad: "", fields: initialFields },
            section2: { carName: "Masina 2", notepad: "", fields: initialFields },
            section3: { carName: "Masina 3", notepad: "", fields: initialFields },
          });
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [authUser]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // ============ Field Handlers ============
  const handleToggleMail = (section, index) => {
    setData((prevData) => {
      const updatedFields = prevData[section].fields.map((item, i) =>
        i === index ? { ...item, mailEnabled: !item.mailEnabled } : item
      );
      return {
        ...prevData,
        [section]: { ...prevData[section], fields: updatedFields },
      };
    });
  };

  const handleToggleSMS = (section, index) => {
    setData((prevData) => {
      const updatedFields = prevData[section].fields.map((item, i) =>
        i === index ? { ...item, smsEnabled: !item.smsEnabled } : item
      );
      return {
        ...prevData,
        [section]: { ...prevData[section], fields: updatedFields },
      };
    });
  };

  const handleClearField = (section, index) => {
    setData((prevData) => {
      const updatedFields = prevData[section].fields.map((item, i) =>
        i === index ? { ...item, value: "" } : item
      );
      return {
        ...prevData,
        [section]: { ...prevData[section], fields: updatedFields },
      };
    });
  };

  const handleClearAllFields = (section) => {
    setData((prevData) => {
      const clearedFields = prevData[section].fields.map((item) => ({
        ...item,
        value: "",
      }));
      return {
        ...prevData,
        [section]: { ...prevData[section], fields: clearedFields },
      };
    });
  };

  const handleUpdateField = (section, index, field, value) => {
    setData((prevData) => {
      const updatedFields = prevData[section].fields.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      );
      return {
        ...prevData,
        [section]: { ...prevData[section], fields: updatedFields },
      };
    });
  };

  // ============ Notepad Handler ============
  const handleNotepadChange = (section, value) => {
    setData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        notepad: value,
      },
    }));
  };

  // ============ Save / Edit Handlers ============
  const handleSave = () => {
    if (!authUser) return;

    const sectionsRef = ref(database, `users/${authUser.uid}/sections`);
    // We'll build one big `updates` object to push to `sections`
    const updates = {};

    Object.keys(data).forEach((section) => {
      updates[section] = {
        carName: data[section].carName || "Numar Inmatriculare",
        notepad: data[section].notepad || "",
        fields: data[section].fields.map((field) => ({
          tag: field.tag,
          label: field.label,
          value: field.value || "",
          mailEnabled: field.mailEnabled ?? false,
          smsEnabled: field.smsEnabled ?? false,
        })),
      };
    });

    update(sectionsRef, updates)
      .then(() => {
        setIsEditing(false);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => setIsEditing(false);

  // ============ Car Name Handler ============
  const handleCarNameChange = (section, value) => {
    setData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        carName: value,
      },
    }));
  };

  // ============ Days Left Utility ============
  const calculateDaysLeft = (expiryDate) => {
    if (!expiryDate) return { days: null, color: "grey" };
    const today = new Date();
    const targetDate = new Date(expiryDate);
    const timeDiff = targetDate - today;
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    let color;
    let displayDays;

    if (daysLeft < 0) {
      color = "#111111";
      displayDays = "Expirat"; // Afișăm "Expirat" dacă data este în trecut
    } else if (daysLeft === 0) {
      color = "#b4150f";
      displayDays = "Ultima zi"; // Mesaj special pentru ultima zi
    } else if (daysLeft < 10) {
      color = "#b4150f";
      displayDays = `${daysLeft} zile`;
    } else if (daysLeft < 30) {
      color = "#d69e24";
      displayDays = `${daysLeft} zile`;
    } else {
      color = "#04ad0c";
      displayDays = `${daysLeft} zile`;
    }

    return { days: displayDays, color, daysLeft };
  };

  // ============ Rendering ============

  // Renders the fields portion of a section
  const renderFields = (section) => (
    <div>
      <div className="userpanel-heading">
        <h2 className="userpanel-heading-text">Documente & Notificări</h2>
        <p className="userpanel-heading-paragraph">
          Activează notificările pe mail și SMS și primești alertă la 1, 15 și
          30 de zile înainte de expirare.
        </p>
      </div>
      <div className="row mt-4">
        <div className="col">
          <h5>Mașina selectată:</h5>
          <p className="userpanel-heading-paragraph">
            Apasă pe butonul „modifică” pentru a edita.
          </p>
          <input
            type="text"
            value={data[section].carName}
            onChange={(e) => handleCarNameChange(section, e.target.value)}
            disabled={!isEditing}
            className={`car-name-input ${isEditing ? "editable" : ""}`}
          />
        </div>
      </div>
      <div className="row mt-5 data-index">
        <div className="col index-col">
          <h5>Tip Document</h5>
        </div>
        <div className="col">
          <h5>Dată Expirare</h5>
        </div>
        <div className="col">
          <h5>Zile Rămase</h5>
        </div>
        <div className="col responsive-col"></div>
        <div className="col responsive-col"></div>
      </div>
      {data[section].fields.map((field, index) => {
        const { days, color } = calculateDaysLeft(field.value);
        return (
          <div key={index} style={{ marginBottom: "10px" }} className="mt-4">
            <div className="row userpanel-row">
              <div className="col">
                <label style={{ marginRight: "10px" }} className="label-name">
                  {field.label}
                </label>
              </div>
              <div className="col">
                <input
                  type="date"
                  placeholder="dd-mm-yyyy"
                  className="input-table"
                  value={field.value}
                  onChange={(e) =>
                    handleUpdateField(section, index, "value", e.target.value)
                  }
                  disabled={!isEditing}
                  style={{ marginRight: "10px" }}
                />
              </div>
              <div className="col">
                <p
                  className="days-left"
                  style={{
                    backgroundColor: color,
                    color:
                      color === "black" || color === "grey" ? "#fff" : "#333",
                  }}
                >
                  {days !== null ? `${days}` : "Nesetat"}
                </p>
              </div>
              <div className="col switch-col">
                <p className="px-2 switch-text">Mail</p>
                <label className="switch-button">
                  <input
                    type="checkbox"
                    checked={field.mailEnabled || false}
                    onChange={() => handleToggleMail(section, index)}
                    disabled={!isEditing}
                  />
                  <span className="slider"></span>
                </label>
              </div>
              <div className="col switch-col">
                <p className="px-2 switch-text">SMS</p>
                <label className="switch-button">
                  <input
                    type="checkbox"
                    checked={field.smsEnabled || false}
                    onChange={() => handleToggleSMS(section, index)}
                    disabled={!isEditing}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            </div>
            {isEditing && (
              <button
                onClick={() => handleClearField(section, index)}
                className="button-delete"
              >
                Șterge
              </button>
            )}
          </div>
        );
      })}
      {isEditing && (
        <button onClick={() => handleClearAllFields(section)} className="mt-5 button-delete">
          Șterge tot
        </button>
      )}
    </div>
  );

  // Renders the notepad portion of a section
  const renderNotepad = (section) => (
    <div className="container notepad-section mt-5">
      <div className="userpanel-heading mb-4">
        <h2 className="userpanel-heading-text">Câmp Personalizat (Notițe)</h2>
        <p className="userpanel-heading-paragraph">
          Secțiune dedicată evidențelor personalizate sau istoricul mașinii.
        </p>
      </div>
      {isEditing ? (
        <textarea
          className="notepad-textarea"
          value={data[section].notepad || ""}
          onChange={(e) => handleNotepadChange(section, e.target.value)}
        />
      ) : (
        <pre className="notepad-display">{data[section].notepad || ""}</pre>
      )}
    </div>
  );

  return (
    <div>
      {/* Single edit/save button row */}
      <div style={{ marginTop: "10px" }} className="edit-table-div">
        {isEditing ? (
          <>
            <button onClick={handleSave} className="button-save">
              Salvează
            </button>
            <button onClick={handleCancel} className="button-abandon">
              Renunță
            </button>
          </>
        ) : (
          <button onClick={handleEdit} className="button-edit">
            Modifică
          </button>
        )}
      </div>

      <div className="container userpanel-container">
        <div className="userpanel-heading userpanel-big-title">
          <h2 className="userpanel-heading-text">Mașinile mele</h2>
          <p className="userpanel-heading-paragraph">
            Alege mașina pentru care dorești să vizionezi documentele.
          </p>
        </div>

        {/* Cars and details */}
        <div style={{ padding: "20px" }} className="container infouser-container">
          <div className="mt-4 mb-5">
            <button
              onClick={() => setActiveSection("section1")}
              className={`button-car ${activeSection === "section1" ? "active-car" : ""}`}
            >
              {data.section1.carName}
            </button>
            <button
              onClick={() => setActiveSection("section2")}
              className={`button-car mx-5 ${activeSection === "section2" ? "active-car" : ""}`}
            >
              {data.section2.carName}
            </button>
            <button
              onClick={() => setActiveSection("section3")}
              className={`button-car ${activeSection === "section3" ? "active-car" : ""}`}
            >
              {data.section3.carName}
            </button>
          </div>

          {/* Document Fields */}
          <div style={{ marginTop: "20px" }}>
            {activeSection && renderFields(activeSection)}
          </div>

          {/* Notepad Section */}
          <div style={{ marginTop: "20px" }}>
            {activeSection && renderNotepad(activeSection)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
