import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../backend/firebaseConfig";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handlePasswordReset = (e) => {
    e.preventDefault();

    setError(""); // Clear previous error messages
    setSuccess(""); // Clear previous success messages

    if (!email) {
      setError("Te rugăm să introduci o adresă de email.");
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccess("Un email pentru resetarea parolei a fost trimis. Verifică adresa de email.");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          setError("Nu există un cont cu această adresă de email.");
        } else {
          setError("A apărut o eroare. Încercați din nou.");
        }
        console.error("Error sending password reset email:", error);
      });
  };

  return (
    <main className="container form-forgot-password-container m-auto">
      <form className="form-forgot-password" onSubmit={handlePasswordReset}>
        <h1 className="display-5s">Ai uitat parola?</h1>
        <h1 className="h3 mb-3 fw-normal">Alerte Expirare</h1>

        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">{success}</div>}

        <div className="form-floating">
          <input
            type="email"
            className="form-control"
            id="floatingInput"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="floatingInput">Adresa de mail</label>
        </div>

        <button className="btn btn-primary w-100 py-2 mt-3" type="submit">
          Resetează parola
        </button>
        <p className="mt-5 mb-3 text-body-secondary text-center">
          &copy;2024. Alerte Expirare. Toate drepturile Rezervate.
        </p>
      </form>
    </main>
  );
}

export default ForgotPassword;
