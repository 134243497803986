import React from "react";
import './footer.css'


function Footer () {

 return (
    <div className="footer-container">
        <div className="container">
            <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 mt-3 mb-1 border-top footer-responsive">
                <div className="col mb-3">
                    <a href="/" className="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
                        <h1><span className="color-span">e</span>RCA</h1>
                    </a>
                    <p className="text-body-secondary footer-text">Blu Insurance Company SRL <br />
                                                CUI: 47596717 <br />
                                                Registru Comerț J40/14099/2024
                    </p>
                    <div className="d-flex mt-3 mb-3">  {/* Change to d-flex for Flexbox layout */}
                        <div className="me-2">  {/* Adds margin to the right */}
                            <a href="https://anpc.ro/ce-este-sal/"><img className="img-fluid" width="150" src="/images/anpc-img.webp" href="" alt="ANPC Image" /></a>
                        </div>
                        <div>
                            <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"><img className="img-fluid" width="150" src="/images/sol-img.webp" href="" alt="SOL Image" /></a>
                        </div>
                    </div>
                </div>
                <div className="col mb-3">

                </div>
                <div className="col mb-3">
                    <h5>Navigare Web</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-body-secondary">Pagina Principală</a></li>
                        <li className="nav-item mb-2"><a href="/contact" className="nav-link p-0 text-body-secondary">Informații de contact</a></li>
                        <li className="nav-item mb-2"><a href="/login" className="nav-link p-0 text-body-secondary">Contul meu</a></li>
                        <li className="nav-item mb-2"><a href="/cont-nou" className="nav-link p-0 text-body-secondary">Înregistrare</a></li>
                        <li className="nav-item mb-2"><a href="/rca" className="nav-link p-0 text-body-secondary">Cumpără RCA</a></li>
                    </ul>
                </div>

                <div className="col mb-3">
                    <h5>Documente Legale</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="/politica-de-confidentialitate" className="nav-link p-0 text-body-secondary">Politica de confidențialitate</a></li>
                        <li className="nav-item mb-2"><a href="/termeni-si-conditii" className="nav-link p-0 text-body-secondary">Termeni și condiții</a></li>
                        <li className="nav-item mb-2"><a href="/politica-cookies" className="nav-link p-0 text-body-secondary">Politica cookie-urilor</a></li>
                        <li className="nav-item mb-2"><a href="https://anpc.ro/ce-este-sal/" className="nav-link p-0 text-body-secondary">ANPC</a></li>
                        <li className="nav-item mb-2"><a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" className="nav-link p-0 text-body-secondary">SAL/SOL</a></li>
                    </ul>
                </div>

                <div className="col mb-3">
                    <h5>Link-uri Utile</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="https://www.carvertical.com/ro" className="nav-link p-0 text-body-secondary">Car Vertical</a></li>
                        <li className="nav-item mb-2"><a href="https://dgpci.mai.gov.ro/" className="nav-link p-0 text-body-secondary">DRPCIV</a></li>
                        <li className="nav-item mb-2"><a href="https://www.ghiseul.ro/ghiseul/public/" className="nav-link p-0 text-body-secondary">Ghișeul</a></li>
                        <li className="nav-item mb-2"><a href="https://www.erovinieta.ro/vignettes-portal-web/#/login" className="nav-link p-0 text-body-secondary">eRovinietă</a></li>
                        <li className="nav-item mb-2"><a href="https://www.toplacservice.ro/Content/download_constat_amiabil.pdf" className="nav-link p-0 text-body-secondary">Formular amiabilă</a></li>
                        <li className="nav-item mb-2"><a href="https://dgpci.mai.gov.ro/document-details/taxe/5ac1de72b729455700e94e09" className="nav-link p-0 text-body-secondary">Contract vânzare-cumpărare</a></li>
                    </ul>
                </div>
            </footer>
        </div>
        <div className="container py-3 logo-container">
            <img src="/images/grafic1.svg" alt="Logo 1" />
            <img src="/images/grafic2.svg" alt="Logo 2" />
            <img src="/images/grafic3.svg" alt="Logo 3" className="third-logo" />
            <img src="/images/grafic4.svg" alt="Logo 4" />
            <img src="/images/grafic5.svg" alt="Logo 5" />
            <img src="/images/grafic6.svg" alt="Logo 6" />
            <img src="/images/grafic7.svg" alt="Logo 7" />
            <img src="/images/grafic8.svg" alt="Logo 8" />
            <img src="/images/grafic9.svg" alt="Logo 9" />
        </div>
        <div className="container-fluid copyright text-center d-flex align-items-center justify-content-center">
            <p className="text-body-emphasize" style={{ margin: 0 }}>
                &copy; eRCA. Toate Drepturile Rezervate. Developed by <a href="https://webmania.ro" className="fw-bold link-web">Webmania</a>
            </p>
        </div>
    </div>
 );

}

export default Footer;