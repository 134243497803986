import React, { useState } from "react";
import "./cont-nou.css";
import { Link } from "react-router-dom";
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { ref, set } from "firebase/database";
import { auth, database } from "../backend/firebaseConfig";
import { useNavigate } from "react-router-dom";

function Signup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(""); // State to store success messages
  const navigate = useNavigate();

  // Helper function to validate name
  const validateName = () => {
    const nameRegex = /^[a-zA-Z0-9\s]+$/;
    if (!nameRegex.test(name)) {
      return "Numele trebuie să conțină doar litere, cifre și spații.";
    }
    return "";
  };

  // Helper function to validate email
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Adresa de email nu este validă.";
    }
    return "";
  };

  // Helper function to validate password
  const validatePassword = () => {
    if (password !== confirmPassword) {
      return "Parolele sunt diferite.";
    }
    if (password.length < 6) {
      return "Parola trebuie să conțină minim 6 caractere.";
    }
    const nonLetterRegex = /[^a-zA-Z]/;
    if (!nonLetterRegex.test(password)) {
      return "Parola trebuie să conțină cel puțin un caracter care nu este literă.";
    }
    return "";
  };

  // Helper function to validate phone number
  const validatePhone = () => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      return "Număr de telefon invalid.";
    }
    return "";
  };

  // Handle form submission
  const signUp = (e) => {
    e.preventDefault();

    // Perform validations
    const nameError = validateName();
    if (nameError) {
      setError(nameError);
      return;
    }

    const emailError = validateEmail();
    if (emailError) {
      setError(emailError);
      return;
    }

    const passwordError = validatePassword();
    if (passwordError) {
      setError(passwordError);
      return;
    }

    const phoneError = validatePhone();
    if (phoneError) {
      setError(phoneError);
      return;
    }

    if (!acceptedPolicy) {
      setError("Nu ați acceptat politica de confidențialitate.");
      return;
    }

    // Clear the error message if the validation passes
    setError("");

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        // Save user data to Firebase Realtime Database
        const userRef = ref(database, `users/${user.uid}/profile`);
        set(userRef, {
          name: name,
          phone: phone,
        })
          .then(() => {
            // Send email verification
            sendEmailVerification(user)
              .then(() => {
                setSuccess("Cont creat cu succes! Vă rugăm să verificați email-ul pentru a confirma adresa.");

                // Sign out the user
                signOut(auth)
                  .then(() => {
                    // User signed out
                  })
                  .catch((error) => {
                    console.error("Error signing out:", error);
                  });
              })
              .catch((error) => {
                console.log("Error sending email verification:", error);
                setError("Eroare la trimiterea email-ului de verificare. Încercați din nou.");
              });
          })
          .catch((error) => {
            console.log("Error saving user data:", error);
            setError("Eroare la salvarea datelor. Încercați din nou.");
          });
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          setError("Există deja un cont cu această adresă de email.");
        } else {
          setError(error.message);
        }
      });
  };

  return (
    <main className="container form-signup-container m-auto">
      <form className="form-signup" onSubmit={signUp}>
        <h1 className="display-5s">Creează un cont</h1>
        <h1 className="h3 mb-3 fw-bold"><span className="color-span">e</span>RCA</h1>

        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">{success}</div>}

        <div className="form-floating">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Popescu Ion"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={!!success}
          />
          <label htmlFor="floatingInput">Nume</label>
        </div>

        <div className="form-floating mt-3">
          <input
            type="email"
            className="form-control"
            id="floatingInput"
            placeholder="nume@exemplu.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!!success}
          />
          <label htmlFor="floatingInput">Adresa de mail</label>
        </div>

        <div className="form-floating mt-3">
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Parola"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={!!success}
          />
          <label htmlFor="floatingPassword">Parola</label>
        </div>

        <div className="form-floating mt-3">
          <input
            type="password"
            className="form-control"
            id="floatingPasswordConfirm"
            placeholder="Confirmare Parolă"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={!!success}
          />
          <label htmlFor="floatingPasswordConfirm">Confirmare Parolă</label>
        </div>

        <div className="form-floating mt-3">
          <input
            type="text"
            className="form-control"
            id="floatingPhone"
            placeholder="Telefon"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            disabled={!!success}
          />
          <label htmlFor="floatingPhone">Telefon</label>
        </div>

        <div className="form-check text-start my-3">
          <input
            className="form-check-input"
            type="checkbox"
            value="remember-me"
            id="flexCheckDefault"
            checked={acceptedPolicy}
            onChange={() => setAcceptedPolicy(!acceptedPolicy)}
            disabled={!!success}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Sunt de acord cu{" "}
            <Link to="/politica-de-confidentialitate">politica de confidențialitate</Link>.
          </label>
        </div>

        {!success && (
          <button className="btn btn-primary w-100 py-2 btn-size-signup" type="submit">
            Creează cont
          </button>
        )}
        <Link to="/login" className="btn btn-outline-secondary w-100 py-2 mt-2 btn-size-signup">
          Ai deja un cont? Intră aici.
        </Link>
        <p className="mt-5 mb-3 text-body-secondary text-center">
          &copy; eRCA. Toate drepturile Rezervate.
        </p>
      </form>
    </main>
  );
}

export default Signup;
