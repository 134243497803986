import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container py-5">
        <h1>Termeni și Condiții</h1>
            <section>
            <h2>1. Introducere</h2>
            <p>
                Acest document stabilește termenii și condițiile utilizării serviciilor noastre. 
                Prin accesarea și utilizarea platformei noastre, sunteți de acord să respectați aceste condiții.
            </p>
            </section>
            <section>
            <h2>2. Servicii oferite</h2>
            <p>
                Compania noastră furnizează servicii de notificare prin e-mail și SMS privind expirarea 
                documentelor auto, pe baza informațiilor introduse manual de către utilizator.
            </p>
            </section>
            <section>
            <h2>3. Datele prelucrate</h2>
            <p>
                Pentru furnizarea serviciilor, colectăm și prelucrăm următoarele date personale:
            </p>
            <ul>
                <li>Nume și prenume</li>
                <li>Detalii de pe buletin (poza sau PDF)</li>
                <li>Permis auto (poza sau PDF)</li>
                <li>Certificat de înmatriculare auto (poza sau PDF)</li>
                <li>Kilometrii auto</li>
                <li>Număr de telefon</li>
                <li>Seria de șasiu</li>
            </ul>
            <p>
                Toate datele sunt utilizate exclusiv în scopul furnizării notificărilor și sunt protejate 
                conform legilor aplicabile privind protecția datelor personale (GDPR).
            </p>
            </section>
            <section>
            <h2>4. Responsabilitatea utilizatorului</h2>
            <p>Utilizatorul este responsabil pentru:</p>
            <ul>
                <li>Introducerea corectă și actualizată a informațiilor necesare pentru notificări;</li>
                <li>Păstrarea confidențialității contului și a datelor de autentificare;</li>
                <li>Respectarea termenilor și condițiilor prezentului document.</li>
            </ul>
            </section>
            <section>
            <h2>5. Răspunderea companiei</h2>
            <p>Compania noastră nu își asumă răspunderea pentru:</p>
            <ul>
                <li>Orice pierderi sau daune cauzate de introducerea incorectă a datelor de către utilizator;</li>
                <li>Neprimirea notificărilor din motive tehnice independente de voința noastră;</li>
                <li>Utilizarea neautorizată a contului de către terți.</li>
            </ul>
            </section>
            <section>
            <h2>6. Confidențialitatea datelor</h2>
            <p>
                Datele utilizatorilor sunt stocate în condiții de siguranță și nu sunt partajate cu terțe 
                părți fără consimțământul prealabil al utilizatorului, exceptând cazurile în care legea o impune.
            </p>
            </section>
            <section>
            <h2>7. Drepturile utilizatorului</h2>
            <p>Utilizatorii au dreptul de a solicita:</p>
            <ul>
                <li>Accesul la datele personale colectate;</li>
                <li>Corectarea sau ștergerea datelor personale;</li>
                <li>Retragerea consimțământului pentru prelucrarea datelor.</li>
            </ul>
            <p>
                Pentru orice solicitări legate de datele personale, utilizatorii pot contacta compania la adresa de e-mail: 
                <strong> contact@erca.ro</strong>.
            </p>
            </section>
            <section>
            <h2>8. Modificări ale termenilor și condițiilor</h2>
            <p>
                Ne rezervăm dreptul de a modifica acest document, iar utilizatorii vor fi informați despre 
                orice modificări prin e-mail sau notificări în platformă.
            </p>
            </section>
            <section>
            <h2>9. Contact</h2>
            <p>
                Pentru orice întrebări legate de acești termeni și condiții, vă rugăm să ne contactați la:
            </p>
            <p>
                <strong>Telefon:</strong> +40 722 985 433<br />
                <strong>E-mail:</strong> contact@erca.ro
            </p>
            </section>
        </div>
  );
};

export default TermsAndConditions;
