import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faBell, faUser } from '@fortawesome/free-solid-svg-icons';
import './navbar.css';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../backend/firebaseConfig';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, setLoading } from '../backend/authSlice';


function MainNav() {
  const [fix, setFix] = useState(false);
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.auth.user);
  const authLoading = useSelector((state) => state.auth.loading);

  const handleScroll = () => {
    if (window.scrollY >= 80) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      dispatch(setLoading(true)); // Start auth check

      if (user) {
        dispatch(login(user));
      } else {
        dispatch(logout());
      }

      dispatch(setLoading(false)); // Auth check is done
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [dispatch]);

  const handleLogout = () => {
    signOut(auth);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Show loading state while auth status is resolving
  if (authLoading) {
    return (
      <Navbar collapseOnSelect expand="lg" className={fix ? 'navbar fixed' : 'navbar'}>
        <Container>
          <Navbar.Brand href="/" className="logo"><span className="color-span">e</span>RCA</Navbar.Brand>
        </Container>
      </Navbar>
    );
  }
  
  return (
    <Navbar collapseOnSelect expand="lg" className={fix ? 'navbar fixed' : 'navbar'}>
      <Container>
        <Navbar.Brand href="/" className="logo"><span className="color-span">e</span>RCA</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{ maxHeight: '100px' }} navbarScroll>
          </Nav>
          <Nav>
            <Nav>
              {authUser ? (
                <Nav>
                  <Button variant="link" className="logout-btn nav-responsive-btn" onClick={handleLogout}>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} className="logout-icon" />Logout
                  </Button>
                  <Button variant="link" href="/contul-meu" className="login-btn nav-responsive-btn">
                    <FontAwesomeIcon icon={faUser} className="login-icon" />Contul meu
                  </Button>
                  <Button variant="link" href="/userpanel" className="notifications-btn nav-responsive-btn">
                    <FontAwesomeIcon icon={faBell} className="notifications-icon" />Notificări
                  </Button>
                </Nav>
              ) : (
                <Nav>
                  <Button variant="link" href="/login" className="login-btn nav-responsive-btn">
                    <FontAwesomeIcon icon={faUser} className="login-icon" />Login
                  </Button>
                </Nav>
              )}
            </Nav>
            {!authUser && (
              <Button variant="primary" href="/cont-nou" size="m" className="btn-register nav-responsive-btn">Creează Cont</Button>
            )}
            <Button variant="primary" href="/rca" size="m" className="rca-btn">Cumpără RCA</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNav;
