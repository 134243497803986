// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice'; // Import the auth slice

// Create and export the store
const store = configureStore({
  reducer: {
    auth: authReducer, // Add auth reducer to the store
  },
});

export default store;