import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="container py-5">
      <header>
        <h1>Politica de Cookie-uri</h1>
      </header>
      <main>
        <section>
          <h2>1. Introducere</h2>
          <p>
            La <strong>Blu Insurance Company SRL</strong>, folosim doar cookie-uri esențiale pentru funcționarea optimă a
            website-ului nostru. Această politică explică ce sunt cookie-urile, cum le utilizăm și ce opțiuni aveți.
          </p>
        </section>
        <section>
          <h2>2. Ce sunt cookie-urile?</h2>
          <p>
            Cookie-urile sunt fișiere mici de text stocate pe dispozitivul dumneavoastră (computer, telefon mobil sau
            alt dispozitiv) atunci când accesați un website. Acestea sunt utilizate pentru a îmbunătăți experiența
            utilizatorului și pentru a asigura funcționarea corectă a website-ului.
          </p>
        </section>
        <section>
          <h2>3. Tipuri de cookie-uri utilizate</h2>
          <p>Website-ul nostru utilizează doar cookie-uri esențiale, care sunt necesare pentru:</p>
          <ul>
            <li>Asigurarea funcționării corecte a website-ului;</li>
            <li>Gestionarea sesiunilor utilizatorilor autentificați;</li>
            <li>Reținerea setărilor și preferințelor utilizatorilor (cum ar fi limba).</li>
          </ul>
          <p>
            Aceste cookie-uri nu colectează informații care ar putea fi utilizate pentru a vă identifica în scopuri de
            marketing sau pentru a urmări activitatea pe alte website-uri.
          </p>
        </section>
        <section>
          <h2>4. Temeiul legal pentru utilizarea cookie-urilor</h2>
          <p>
            Utilizăm cookie-uri esențiale în baza interesului nostru legitim de a asigura funcționarea corespunzătoare a
            website-ului și de a furniza serviciile solicitate de utilizatori.
          </p>
        </section>
        <section>
          <h2>5. Gestionarea cookie-urilor</h2>
          <p>
            Deoarece folosim doar cookie-uri esențiale, acestea nu pot fi dezactivate fără a afecta funcționalitatea
            website-ului. Dacă doriți să blocați sau să ștergeți cookie-urile, puteți face acest lucru din setările
            browserului dumneavoastră, însă este posibil ca website-ul nostru să nu funcționeze corect.
          </p>
        </section>
        <section>
          <h2>6. Actualizări ale politicii de cookie-uri</h2>
          <p>
            Această politică poate fi actualizată periodic. Orice modificare va fi publicată pe această pagină, iar
            utilizatorii vor fi informați dacă este cazul.
          </p>
        </section>
        <section>
          <h2>7. Contact</h2>
          <p>
            Dacă aveți întrebări legate de politica noastră de cookie-uri, vă rugăm să ne contactați:
          </p>
          <p>
            <strong>Email:</strong> contact@erca.ro<br />
            <strong>Telefon:</strong> +40 722 985 433<br />
            <strong>Adresă:</strong> București, Bd. Lacul Tei 79
          </p>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 Blu Insurance Company SRL - Toate drepturile rezervate.</p>
      </footer>
    </div>
  );
};

export default CookiePolicy;
