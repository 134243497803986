import './home.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

function Home() {
 return (
    <div>

        {/* Hero Section */}
        <div className="hero-section-background">
            <div class="px-4 py-4 my-5 text-center hero-section">
                <h1 class="display-2 fw-bold text-body-emphasis hs-title text-center">Alerte pe SMS și email pentru documente AUTO</h1>
                <div class="col-lg-6 mx-auto">
                    <p class="lead mb-4 mt-4 text-phone">Totul este GRATUIT! Primești 3 SMS-uri gratuite și email-uri de la noi cu câteva zile înainte de data expirării. 
                                        Poți vedea și pe platformă toate informațiile în timp real.</p>
                    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                        <Button type="button" class="btn btn-primary btn-lg px-4 gap-3" size="lg" href="/cont-nou">Creează cont</Button>
                        <Button type="button" variant="outline-secondary" size="lg" class="btn btn-outline-secondary btn-lg px-4" href="/rca">Cumpără RCA</Button>
                    </div>  
                </div>
                <img  className="d-block mx-lg-auto img-fluid mt-4 img-rounded" src={'/images/herosection.png'} alt="Alerte Expirare Documente Auto" width="800"/>
            </div>
        </div>

        {/* RCA graficS PARTNERS */}
        <div className="container py-3 logo-container">
            <img src="/images/grafic1.svg" alt="Logo 1" />
            <img src="/images/grafic2.svg" alt="Logo 2" />
            <img src="/images/grafic3.svg" alt="Logo 3" className="third-logo" />
            <img src="/images/grafic4.svg" alt="Logo 4" />
            <img src="/images/grafic5.svg" alt="Logo 5" />
            <img src="/images/grafic6.svg" alt="Logo 6" />
            <img src="/images/grafic7.svg" alt="Logo 7" />
            <img src="/images/grafic8.svg" alt="Logo 8" />
            <img src="/images/grafic9.svg" alt="Logo 9" />
        </div>
        {/* Section 2 */}
        <div class="container px-4 py-3 section-2">
            <h2 class="pb-2 border-bottom">Ce oferim noi?</h2>
            <div class="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
                <div class="col d-flex flex-column align-items-start gap-2">
                    <h2 class="fw-bold display-5 fw-bold text-body-emphasis">Alerte de expirare, evidență în timp real și personalizare.</h2>
                    <p class="text-body-secondary lead">Cu ajutorul platformei noastre, puteți ține evidența și evita o amendă foarte simplu. Pe lângă notificările principale, puteți monitoriza și alte aspecte personalizate pentru mașina dumneavoastră. (ex. <span className="fw-bold">Istoric Service</span>)</p>
                </div>
                <div class="col">
                    <div class="row row-cols-1 row-cols-sm-2 g-5 gap-phone">
                        <div class="col d-flex flex-column gap-2">
                            <h4 class="fw-semibold mb-0 mt-0 text-body-emphasis"> <FontAwesomeIcon icon={faSquareCheck} className="icon-check" /> Alertă RCA</h4>
                        </div>
                        <div class="col d-flex flex-column gap-2">
                            <h4 class="fw-semibold mb-0 mt-0 text-body-emphasis"> <FontAwesomeIcon icon={faSquareCheck} className="icon-check" /> Alertă ITP</h4>
                        </div>
                        <div class="col d-flex flex-column gap-2">
                            <h4 class="fw-semibold mb-0 mt-0 text-body-emphasis"><FontAwesomeIcon icon={faSquareCheck} className="icon-check" /> Alertă Rovinietă</h4>
                        </div>
                        <div class="col d-flex flex-column gap-2">
                            <h4 class="fw-semibold mb-0 mt-0 text-body-emphasis"><FontAwesomeIcon icon={faSquareCheck} className="icon-check" /> Alertă Casco</h4>
                        </div>
                        <div class="col d-flex flex-column gap-2">
                            <h4 class="fw-semibold mb-0 mt-0 text-body-emphasis"><FontAwesomeIcon icon={faSquareCheck} className="icon-check" /> Istoric Carte Service</h4>
                        </div>
                        <div class="col d-flex flex-column gap-2">
                            <h4 class="fw-semibold mb-0 mt-0 text-body-emphasis"><FontAwesomeIcon icon={faSquareCheck} className="icon-check" /> Câmpuri Personalizate</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* Section 3 */}
        <div class="container col-xxl-12 px-4 pb-5 section-3">
            <div class="row flex-lg-row-reverse align-items-center g-5 py-3">
                <div class="col-12 col-sm-8 col-lg-6">
                    <h2 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">Evită o amendă neplăcută. Fii informat cu privire la expirarea documentelor tale.</h2>
                    <p class="text-body-secondary lead">E foarte ușor să uităm! Dar mai ușor este să folosim platforma eRCA în mod <span className="fw-bold">gratuit</span>. Cu ajutorul nostru veți putea ține evidența expirării documentelor importante ale mașinii dumneavoastră.</p>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                        <Button type="button" size="lg" class="btn btn-primary btn-lg px-4 me-md-2" href="/cont-nou">Creează un cont</Button>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src={'/images/section3.jpg'} class="d-block mx-lg-auto img-fluid img-rounded" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
                </div>
            </div>
        </div>
    
    </div>
 );
}

export default Home;