import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './contact.css';  // For custom styling

function Contact() {
  return (
    <Container className="contact-page py-6">
      <Row className="justify-content-center heading-contact">
        <Col md={6} className="text-center">
          <h2>Contactează-ne</h2>
          <p className="text-muted mb-4">Suntem aici să te ajutăm cu informații.</p>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={8} className="text-center mb-4 mb-md-0">
          <img src={'/images/contact.jpg'} alt="Contact" className="img-fluid contact-image" />
        </Col>
        <Col md={4}>
          <div className="contact-details">
            <h4>Informații de contact</h4>
            <div className="business-info">
              <p>Blu Insurance Company SRL<br />
                CUI: 47596717<br />
                Registru Comerț J40/14099/2024
              </p>
            </div>
            <p><strong>Adresă:</strong> București, Bd. Lacul Tei 79</p>
            <p><strong>Email:</strong> contact@erca.ro</p>
            <p><strong>Telefon:</strong> +40 722 985 433</p>
            <p><strong>Program de lucru:</strong> Luni-Vineri, 9:00 - 17:00</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
