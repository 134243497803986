import React, { useState, useEffect } from "react";
import "./myaccount.css";
import { Container, Row, Col, Card, Button, Form, Spinner } from "react-bootstrap";
import { ref, set, onValue } from "firebase/database";
import { database } from "../backend/firebaseConfig";
import { useSelector } from "react-redux";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";

const MyAccount = () => {
  const authUser = useSelector((state) => state.auth.user);
  const [userData, setUserData] = useState({
    name: "",
    phone: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  // State for password change
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState("");

  // Load user data from Firebase when the component mounts
  useEffect(() => {
    if (authUser) {
      const userRef = ref(database, `users/${authUser.uid}/profile`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setUserData(data);
        }
        setLoading(false); // Stop loading once data is fetched
      });
    } else {
      setLoading(false); // Stop loading if no auth user
    }
  }, [authUser]);

  // Handle input changes for user data
  const handleChange = (e) => {
    const { id, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Handle save changes for user data
  const handleSave = () => {
    if (authUser) {
      const userRef = ref(database, `users/${authUser.uid}/profile`);
      set(userRef, userData)
        .then(() => {
          console.log("Data saved successfully!");
          setIsEditing(false);
        })
        .catch((error) => {
          console.log("Error saving data:", error);
        });
    }
  };

  // Handle cancel changes for user data
  const handleCancel = () => {
    setIsEditing(false);
    if (authUser) {
      const userRef = ref(database, `users/${authUser.uid}/profile`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setUserData(data);
        }
      });
    }
  };

  // Enable editing for user data
  const handleEdit = () => {
    setIsEditing(true);
  };

  // Validate password
  const validateNewPassword = () => {
    if (newPassword !== confirmPassword) {
      return "Parolele sunt diferite.";
    }
    if (newPassword.length < 6) {
      return "Parola trebuie să conțină minim 6 caractere.";
    }
    const nonLetterRegex = /[^a-zA-Z]/;
    if (!nonLetterRegex.test(newPassword)) {
      return "Parola trebuie să conțină cel puțin un caracter care nu este literă.";
    }
    return "";
  };

  // Handle password change
  const handlePasswordChange = async () => {
    setPasswordError("");
    setPasswordSuccess("");

    const validationError = validateNewPassword();
    if (validationError) {
      setPasswordError(validationError);
      return;
    }

    const credential = EmailAuthProvider.credential(authUser.email, currentPassword);
    try {
      await reauthenticateWithCredential(authUser, credential);
      await updatePassword(authUser, newPassword);
      setPasswordSuccess("Parola a fost schimbată cu succes!");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      if (error.code === "auth/invalid-login-credentials") {
        setPasswordError("Parola curentă este incorectă.");
      } else {
        setPasswordError("Eroare la schimbarea parolei. Încercați din nou.");
      }
      console.error("Password change error:", error);
    }
  };

  // Render loading spinner if data is still loading
  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <Container className="my-5">
        <Row className="justify-content-md-center">
          <Col md={8}>
            <Card className="card">
              <Card.Header as="h4" className="card-header">Contul meu</Card.Header>
              <Card.Body>
                <Form className="form-text">
                  <Form.Group controlId="name">
                    <Form.Label>Nume</Form.Label>
                    <Form.Control
                      type="text"
                      value={userData.name}
                      onChange={handleChange}
                      readOnly={!isEditing}
                      className="placeholder-text"
                    />
                  </Form.Group>

                  <Form.Group controlId="phone">
                    <Form.Label className="mt-3">Telefon</Form.Label>
                    <Form.Control
                      type="text"
                      value={userData.phone}
                      onChange={handleChange}
                      readOnly={!isEditing}
                    />
                  </Form.Group>

                  {isEditing ? (
                    <>
                      <Button variant="success" className="mt-3" onClick={handleSave}>
                        Salvează
                      </Button>
                      <Button variant="secondary" className="mt-3 ms-3" onClick={handleCancel}>
                        Renunță
                      </Button>
                    </>
                  ) : (
                    <Button variant="primary" className="mt-3" onClick={handleEdit}>
                      Modifică informații
                    </Button>
                  )}
                </Form>
              </Card.Body>
            </Card>

            <Card className="mt-4">
              <Card.Header as="h4">Schimbă parola</Card.Header>
              <Card.Body>
                <Form className="form-text">
                  {passwordError && <div className="alert alert-danger">{passwordError}</div>}
                  {passwordSuccess && <div className="alert alert-success">{passwordSuccess}</div>}

                  <Form.Group controlId="currentPassword">
                    <Form.Label>Parola veche</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Introdu parola veche"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="newPassword">
                    <Form.Label className="mt-3">Parola nouă</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Introdu parola nouă"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="confirmPassword">
                    <Form.Label className="mt-3">Confirmă parola nouă</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirmă parola nouă"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Button className="mt-3 card-btn card-pw" onClick={handlePasswordChange}>
                    Schimbă parola
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyAccount;
