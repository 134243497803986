import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component }) => {
  const authUser = useSelector((state) => state.auth.user);
  const authLoading = useSelector((state) => state.auth.loading);

  if (authLoading) {
    return <div></div>; // Show loading indicator while loading auth status
  }

  if (authUser) {
    if (authUser.emailVerified) {
      return component; // Allow access for verified users
    } else {
      return <Navigate to="/verify-email" />; // Redirect unverified users
    }
  }

  return <Navigate to="/login" />; // Redirect unauthenticated users
};

export default ProtectedRoute;
