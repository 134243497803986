// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCzO9VoLgCsd9qJPANvaVC94Tyer4yKmhk",
  authDomain: "proiectalerte.firebaseapp.com",
  databaseURL: "https://proiectalerte-default-rtdb.firebaseio.com",
  projectId: "proiectalerte",
  storageBucket: "proiectalerte.firebasestorage.app",
  messagingSenderId: "26339150359",
  appId: "1:26339150359:web:306fd07e285da227bfea52"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

// Export the services you need
const auth = getAuth(app);
const database = getDatabase(app);

setPersistence(auth, browserSessionPersistence)

export { auth, database, storage };