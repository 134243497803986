import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { auth } from "../backend/firebaseConfig";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const signIn = (e) => {
    e.preventDefault();

    setError(""); // Clear previous error messages
    setSuccess(""); // Clear previous success messages

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        if (user.emailVerified) {
          navigate("/userpanel"); // Navigate to the main page
        } else {
          setError("Adresa de email nu este verificată. Vă rugăm să verificați email-ul.\nUn nou email de verificare a fost trimis.");
          // Sign out the user to prevent unverified access
          signOut(auth).catch((signOutError) => {
            console.error("Error signing out:", signOutError);
          });
        }
      })
      .catch((error) => {
        setError("Datele introduse sunt greșite.");
      });
  };

  return (
    <main className="container form-login-container m-auto">
      <form className="form-login" onSubmit={signIn}>
        <h1 className="display-5s">Accesează contul</h1>
        <h1 className="h3 mb-3 fw-bold"><span className="color-span">e</span>RCA</h1>

        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">{success}</div>}

        <div className="form-floating">
          <input
            type="email"
            className="form-control"
            id="floatingInput"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="floatingInput">Adresa de mail</label>
        </div>
        <div className="form-floating mt-3">
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="floatingPassword">Parola</label>
        </div>
        <button className="btn btn-primary w-100 py-2 mt-4 login-btn text-white" type="submit">
          Intră în cont
        </button>
        <Link to="/cont-nou" className="btn btn-outline-secondary w-100 mt-3 login-btn">
          Nu ai cont? Înregistrează-te
        </Link>
        <Link to="/forgot-password" className="btn btn-link w-100 mt-3 login-btn">
          Ai uitat parola?
        </Link>
        <p className="mt-5 mb-3 text-body-secondary text-center">&copy; eRCA. Toate drepturile Rezervate.</p>
      </form>
    </main>
  );
}

export default Login;
