import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ component }) => {
  const authUser = useSelector((state) => state.auth.user);
  const authLoading = useSelector((state) => state.auth.loading);

  if (authLoading) {
    return <div></div>; // Show loading indicator
  }

  if (authUser) {
    if (authUser.emailVerified) {
      return <Navigate to="/" />; // Redirect verified users to home
    } else {
      return component;
    }
  }

  return component; // Allow unauthenticated users to access public routes
};

export default PublicRoute;
